.FamTy {
  width: 100%;
  padding: 20px;
}
.pageAddPersons {
  padding: 0 20px 20px 20px;
}
.FamTy .Actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
}
.FamTy .Actions i {
  cursor: pointer;
  font-size: 20px;
}
.FamTy .Add {
  width: 40%;
  margin-right: auto;
  padding: 10px 0;
  padding-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.FamTy .Add.cls {
  padding-top: 5px;
  width: 50% !important;
}
.FamTy .Add.cls .label_place:nth-child(2) {
  width: 40%;
  margin-left: 40px;
}
.FamTy .Add .label_place {
  width: 100%;
  position: relative;
}
.FamTy .Add .label_place::before {
  position: absolute;
  content: attr(label-place);
  position: absolute;
  font-size: 14px;
  top: -12px;
  right: 12px;
  padding: 0 4px;
  z-index: 1;
  color: var(--secand-color);
  background-color: var(--main-background);
  transition: all 0.3s ease-in-out;
}
.FamTy .Add input {
  width: 90%;
  /* border: 2px solid var(--three-color); */
  border: 2px solid var(--basic-color);
  background-color: transparent;
  border-radius: 20px 0 20px 0;
  outline: none;
  padding: 10px 20px;
  font-size: 18px;
}
.FamTy main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.FamTy .header-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.FamTy .header-left .add {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-right: 50px;
  transition: all 0.3s ease-in-out;
}
.FamTy .header-left .add .Excel {
  background: lightgreen;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  border-radius: 10px;
  padding: 10px 10px;
  cursor: pointer;
}
.FamTy .header-left .add .Excel i {
  font-size: 25px;
  background-color: transparent;
  color: black;
  padding: 0;
  border-radius: 20px 0 20px 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.FamTy .header-left .add i {
  font-size: 30px;
  background-color: var(--basic-color);
  color: var(--p-color);
  padding: 6px 14px;
  border-radius: 20px 0 20px 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.FamTy .header-left .add .x {
  transform: rotate(45deg);
  padding: 10px;
  border-radius: 50%;
}
.FamTy .input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  position: relative;
}
.FamTy .input {
  border-style: none;
  height: 50px;
  width: 50px;
  padding: 10px;
  outline: none;
  border-radius: 50%;
  transition: 0.5s ease-in-out;
  background-color: var(--basic-color);
  padding-right: 40px;
  color: var(--three-color);
}
.FamTy .input::placeholder,
.FamTy .input {
  font-size: 18px;
}
.FamTy .input::placeholder {
  color: #8f8f8f;
}
.FamTy .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 6px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  outline: none;
  border-style: none;
  border-radius: 50%;
  pointer-events: painted;
  background-color: transparent;
  transition: 0.2s linear;
}
.FamTy .icon i {
  color: var(--p-color);
  font-size: 25px;
  margin-left: 5px;
}
.FamTy .icon:focus ~ .input,
.FamTy .input:focus {
  box-shadow: none;
  width: 200px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 2px solid var(--secand-color);
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}
/*  start  Add Page */
.FamTy .Add_content {
  width: 70%;
  margin: auto;
}
.FamTy .choise {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}
.FamTy .choise::before {
  position: absolute;
  content: attr(before);
  top: 50%;
  transform: translateY(-40%);
  left: 35%;
}

.FamTy .choise::after {
  position: absolute;
  content: attr(after);
  top: 50%;
  transform: translateY(-40%);
  right: 34%;
}
.FamTy .Add_Persons {
  width: 100%;
}
.FamTy .Add_content .from_group,
.FamTy .Add_Persons .from_group {
  /* display: grid; */
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 30px;
  row-gap: 0;
  text-align: center;
}

.FamTy .Add_Persons .sub_border,
.FamTy .Add_content .sub_border {
  padding: 30px 20px;
  border: 2px solid var(--secand-color);
  border-radius: 20px 0 20px 0;
  position: relative;
  margin-bottom: 20px;
}

.FamTy .Add_Persons .sub_border::before,
.FamTy .Add_content .sub_border::before {
  position: absolute;
  content: attr(bordertext);
  top: -15px;
  background-color: var(--main-background);
  padding: 0 10px;
  color: var(--three-color);
}
.FamTy .Add_Persons .sub_border .from_group,
.FamTy .Add_content .sub_border .from_group {
  display: flex;
  align-items: center;
}
.FamTy .Add_Persons .sub_border .from_group span,
.FamTy .Add_content .sub_border .from_group span {
  background-color: var(--secand-color);
  color: var(--three-color);
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  border-radius: 20px 0 20px 0;
}
.FamTy .Add_Persons .sub_border .from_group:nth-child(1) {
  margin-bottom: 20px;
}
.FamTy .Add_content .sub_border .from_group:nth-child(1) {
  margin-bottom: 0;
}
.FamTy .Add_Persons .sub_border .from_group .from_child,
.FamTy .Add_content .sub_border .from_group .from_child {
  margin-bottom: 0;
  width: 100%;
}
.FamTy .Add_Persons .sub_border .from_group .from_child.nowidth {
  width: 100%;
  row-gap: 15px;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.FamTy .Add_content .sub_border .from_group .from_child.nowidth {
  width: 100%;
  row-gap: 25px;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.FamTy .Add_Persons .sub_border .from_group .from_child.nowidth .label_place {
  width: auto;
}
.FamTy .Add_content .sub_border .from_group .from_child.nowidth .label_place {
  max-width: 40%;
  width: auto;
}
.FamTy .Add_content .from_group .from_child,
.FamTy .Add_Persons .from_group .from_child {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}
.FamTy .Add_content .from_group .from_child input,
.FamTy .Add_Persons .from_group .from_child input, 
.FamTy .Add_Persons .from_group .from_child textarea {
  border: 2px solid var(--three-color);
  border-radius: 20px 0 20px 0;
  outline: none;
  width: 100%;
  font-size: 18px;
  color: var(--three-color);
  background-color: transparent;
  padding: 10px 20px;
  resize: none;
  font-family: "El Messiri", sans-serif;
}
.FamTy .Add_content .from_group .from_child select,
.FamTy .Add_Persons .from_group .from_child select {
  border: 2px solid var(--three-color);
  border-radius: 20px 0 20px 0;
  padding: 10px 20px;
  outline: none;
  color: var(--p-color);
  width: 100%;
  font-size: 18px;
  background-color: transparent;
  color: var(--three-color);
  font-family: "El Messiri", sans-serif;
}
.FamTy .Add_content .from_group .from_child select option,
.FamTy .Add_Persons .from_group .from_child select option {
  background-color: var(--nth-child);
  color: var(--three-color);
  font-family: "El Messiri", sans-serif;
}
.FamTy .Add_content .from_group .from_child select option:hover {
  background-color: var(--secand-color) !important;
}
.FamTy .Add_content .label_place,
.FamTy .Add_Persons .label_place {
  width: 100%;
  position: relative;
}
.FamTy .Add_content .label_place::before,
.FamTy .Add_Persons .label_place::before {
  position: absolute;
  content: attr(label-place);
  position: absolute;
  font-size: 14px;
  top: -12px;
  right: 12px;
  padding: 0 4px;
  z-index: 1;
  color: var(--secand-color);
  background-color: var(--main-background);
  transition: all 0.3s ease-in-out;
}
.FamTy .Add_content .btn_add,
.FamTy .Add_Persons .btn_add {
  text-align: center;
}
.FamTy .Add_content .btn_add button,
.FamTy .Add_Persons .btn_add button {
  cursor: pointer;
  position: relative;
  padding: 8px 24px;
  font-size: 18px;
  color: var(--three-color);
  border: 2px solid var(--secand-color);
  border-radius: 20px 0 20px 0;
  background-color: transparent;
  font-weight: 600;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  overflow: hidden;
}
.FamTy .Add_content .btn_add button::before,
.FamTy .Add_Persons .btn_add button::before {
  content: "";
  position: absolute;
  inset: 0;
  margin: auto;
  width: 50px;
  height: 50px;
  border-radius: inherit;
  scale: 0;
  z-index: -1;
  background-color: var(--secand-color);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}
.FamTy .Add_content .btn_add button:hover::before,
.FamTy .Add_Persons .btn_add button:hover::before {
  scale: 3;
}
.FamTy .Add_content .btn_add button:hover,
.FamTy .Add_Persons .btn_add button:hover {
  /* color: #212121; */
  scale: 1.1;
  /* box-shadow: 0 0px 20px var(--secand-color); */
}
.FamTy .Add_content .btn_add button:active,
.FamTy .Add_Persons .btn_add button:active {
  scale: 1;
}
.FamTy .Add_Persons .from_group .from_child input[type="file"] {
  display: none;
}
.FamTy .Add_Persons .from_group .from_child label {
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-align: center; */
  user-select: none;
  white-space: nowrap;
  cursor: pointer;
  border: 2px solid var(--three-color);
  border-radius: 20px 0 20px 0;
  outline: none;
  width: 100%;
  font-size: 18px;
  color: var(--three-color);
  background-color: transparent;
  padding: 10px 20px;
}
.OrphanAdd .Add_Persons .from_group .from_child label {
  display: unset;
  align-items: center;
  justify-content: center;
  user-select: none;
  white-space: nowrap;
  cursor: pointer;
  border: unset;
  border-radius: 20px 0 20px 0;
  outline: none;
  width: unset;
  font-size: 18px;
  color: var(--three-color) !important;
  background-color: var(--main-background);
  padding: 0 5px;
}

.FamTy .Add_Persons .from_group .from_child label i {
  font-size: 25px;
  color: var(--three-color);
  margin-right: 10px;
}
.FamTy .Add_Persons .from_group .from_child .place_image {
  width: 100%;
  margin: auto;
  height: 325px;
  border: 2px dashed var(--three-color);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.FamTy .Add_Persons .from_group .from_child .place_image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 15px;
}
.FamTy .Add_Persons .from_group .from_child .place_image i {
  position: absolute;
  left: -5px;
  top: -5px;
  font-size: 25px;
  background-color: var(--p-color);
  border-radius: 50%;
}

.custum-file-upload {
  height: 120px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  gap: 20px;
  align-items: center;
  justify-content: center;
  border: 2px dashed var(--secand-color);
  background-color: transparent;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0px 48px 35px -48px rgba(0, 0, 0, 0.1);
}
.custum-file-upload .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.custum-file-upload img {
  width: 100%;
}

/*  End  Add Page */

/* start toggle  */
/* The switch - the box around the slider */
.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background: white;
  border-radius: 50px;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.slider:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  right: 0.3em;
  bottom: 0.3em;
  transform: translateX(150%);
  background-color: var(--three-color);
  border-radius: inherit;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.slider:after {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  left: 0.3em;
  bottom: 0.3em;
  background-color: var(--secand-color);
  border-radius: inherit;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px var(--three-color);
}
.switch input:checked + .slider:before {
  transform: translateY(0);
}
.switch input:checked + .slider::after {
  transform: translateX(-150%);
}
/* end toggle  */

/*    start front_page      */
.FamTy .front_page .label_place::before {
  background-color: var(--nth-child);
}
.FamTy .front_page {
  position: fixed;
  /* top: -5%; */
  /* right: -5%; */
  transform: translate(-60%, 0%);
  /* top: -55%;
  right: 40%;
  transform: translate(50%, 50%); */
  padding: 30px 20px;
  background-color: var(--nth-child);
  border-radius: 20px;
  z-index: 88;
}
.FamTy .front_page .Add_content {
  width: 95%;
}
/*    end front_page      */

/* Start front_group */
.front_group {
  position: fixed;
  z-index: 100;
  background: var(--three-color);
  padding: 30px 40px;
  top: 25%;
  right: 25%;
  width: 50%;
  border-radius: 20px;
}
.front_group input {
  border: 2px solid var(--p-color);
  border-radius: 20px 0 20px 0;
  outline: none;
  width: 100%;
  font-size: 18px;
  color: var(--p-color);
  background-color: transparent;
  padding: 10px 20px !important;
  font-family: "El Messiri", sans-serif;
}
.front_group select {
  border: 2px solid var(--p-color);
  border-radius: 20px 0 20px 0;
  outline: none;
  width: 100%;
  font-size: 18px;
  color: var(--p-color);
  background-color: transparent;
  padding: 10px 20px;
  font-family: "El Messiri", sans-serif;
}
.front_group option {
  background-color: var(--nth-child);
  color: var(--three-color);
  font-family: "El Messiri", sans-serif;
}
.front_group .button {
  margin-top: 20px;
  background-color: var(--secand-color);
  border: 0;
  outline: none !important;
  padding: 10px 30px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 20px 0 20px 0;
}
.front_group .from_group {
  text-align: center;
  margin-top: 20px;
}
.front_group .from_group .from_child {
  display: flex;
  column-gap: 20px;
}
.front_group .from_group .from_child:nth-child(2) {
  margin-top: 20px;
}
.front_group .from_group .label_place {
  width: 100%;
  position: relative;
}
.front_group .from_group .label_place::before {
  position: absolute;
  content: attr(label-place);
  position: absolute;
  font-size: 14px;
  top: -12px;
  right: 12px;
  padding: 0 4px;
  z-index: 1;
  color: var(--secand-color);
  background-color: var(--three-color);
  transition: all 0.3s ease-in-out;
}
.front_group .close {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 30px;
  background-color: var(--basic-color);
  color: var(--p-color);
  padding: 6px;
  border-radius: 20px 0 20px 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex;
}
.front_group .choise {
  position: relative;
  text-align: center;
  margin: 20px 0;
}
.front_group .choise::before {
  position: absolute;
  content: "اضافة الشخص الى اكثر من صندوق";
  top: -60%;
  color: var(--p-color);
  font-size: 20px;
  /* transform: translateY(-40%); */
  left: 26%;
}
.front_group .slider:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  right: 0.3em;
  bottom: 0.3em;
  transform: translateX(150%);
  background-color: green;
  border-radius: inherit;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.front_group .slider:after {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  left: 0.3em;
  bottom: 0.3em;
  background-color: var(--nth-child);
  border-radius: inherit;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.front_group .choise::after {
  display: none;
}
.Add_content .from_group .from_child .place_image {
  width: 100%;
  margin: auto;
  height: 200px;
  border: 2px dashed var(--three-color);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.Add_content .from_group .from_child .place_image img {
  width: 95%;
  height: 100%;
  object-fit: contain;
  border-radius: 15px;
}
/* end front_group */
