@import url("https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "El Messiri", sans-serif;
  direction: unset;
  /* background-color: var(--main-background); */
}

div:where(.swal2-container) div:where(.swal2-timer-progress-bar) {
  background-color: var(--secand-color) !important;
}

.bg_color {
  position: absolute;
  content: "";
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100vh;
  z-index: 10;
}
/* Start Header Text */
.Header_text {
  text-align: center;
  padding: 0px 0 10px;
  font-size: 50px;
  color: var(--three-color);
}
.Header_text span {
  color: var(--secand-color);
}
/* End   Header Text */

/* start ag grid style*/
.ag-theme-alpine {
  --ag-foreground-color: var(--three-color);
  --ag-background-color: var(--main-background) !important;
  --ag-selected-row-background-color: var(--p-color) !important;
  --ag-header-foreground-color: var(--p-color) !important;
  --ag-header-background-color: var(--three-color) !important;
  --ag-icon-size: 23px !important;
  --ag-font-size: 16px !important;
  --ag-list-item-height: 30px !important;
  --ag-icon-font-color: var(--secand-color) !important;
}
.ag-header-row{
  direction: rtl;
}
.ag-root-wrapper.ag-layout-normal {
  /* direction: ltr; */
}
.ag-header-cell-sortable .ag-header-cell-label {
  justify-content: center !important;
}
.ag-header-cell-label {
  justify-content: center !important;
}
.ag-header-cell-label .ag-header-cell-text {
  font-size: 20px;
  font-weight: normal;
}
.ag-cell {
  text-align: center;
}
.ag-cell-value,
.ag-group-value {
  direction: rtl !important;
}
.ag-cell-wrapper {
  height: 100%;
}
.ag-header-cell-filtered {
  /* background-color: red !important; */
  /* color: red !important; */
}
.ag-theme-quartz .ag-header-cell {
  font-size: 28px !important;
}
.ag-header-cell-filtered span {
  color: var(--secand-color) !important;
}
.ag-row-odd {
  background-color: var(--nth-child) !important;
}
.ag-cell-focus:not(.ag-cell-range-selected):focus-within {
  border: none !important;
}
.ag-row-hover:not(.ag-full-width-row)::before {
  background-color: transparent !important;
}
.ag-row {
  border-bottom: none !important;
}
.ag-header-cell-filtered span {
  color: var(--secand-color) !important;
}
.ag-cell-focus:not(.ag-cell-range-selected):focus-within {
  border: none !important;
}
.ag-row-hover:not(.ag-full-width-row)::before {
  background-color: transparent !important;
}
/* end ag grid style*/
